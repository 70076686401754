import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* Import language-specific assets */
import en from '../assets/en.jpg';

const PulledTheWord = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const word = location.state?.word;
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  /* Supported language list */
  const supportedLanguages = ['en', 'tr'];
  let userLanguage = i18n.language.split('-')[0];
  const isSupported = supportedLanguages.includes(userLanguage);

  /* Navigate to definition page */
  const handleLearnClick = () => {
    navigate('/Definition', { state: { word } });
  };

  /* Navigate back to Shake page */
  const handleSkipClick = () => {
    navigate('/Shake');
  };

  return (
    <>
      {/* Brand logo and name */}
      <div className="brand">
        <h4>shakeToLearn.com</h4>
        <img src={en} alt="english-logo"></img>
      </div>

      {/* Progress bar for navigation steps */}
      <div className="progress-container">
        <div className="progress-step">{t('shake')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step active">{t('word')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('definition')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('examplesentence')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('synonyms')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('antonyms')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('write_yourself')}</div>
      </div>

      {/* Display of pulled word details */}
      <div className="pulled-word-container">
        {word ? (
          <>
            {/* Content based on supported language */}
            {isSupported && userLanguage !== 'en' ? (
              <div className="centered-container">
                {/* Display word info and translations */}
                <div className="info-container">
                  <p className="part-of-speech">{word.partOfSpeech}</p>
                  <p className="difficulty-level">{word.difficultyLevel}</p>
                </div>

                <img src={word.imagePath} alt={word[`word${userLanguage.toUpperCase()}`]} className="word-image" />
                <h3 className="word-title">{word.word}</h3>
                <h3 className="word-title">{word[`word${userLanguage.toUpperCase()}`]}</h3>

                {/* Navigation buttons */}
                <div className="buttons-container">
                  <button className="learn-button" onClick={handleLearnClick}>{t('learn_button')}</button>
                  <button className="skip-button" onClick={handleSkipClick}>{t('skip_button')}</button>
                </div>
              </div>
            ) : (
              <div className="centered-container">
                {/* Display word info */}
                <div className="info-container">
                  <p className="part-of-speech">{word.partOfSpeech}</p>
                  <p className="difficulty-level">{word.difficultyLevel}</p>
                </div>

                <img src={word.imagePath} alt={word.word} className="word-image" />
                <h3 className="word-title">{word.word}</h3>

                {/* Navigation buttons */}
                <div className="buttons-container">
                  <button className="learn-button" onClick={handleLearnClick}>{t('learn_button')}</button>
                  <button className="skip-button" onClick={handleSkipClick}>{t('skip_button')}</button>
                </div>
              </div>
            )}
          </>
        ) : (
          <p>{t('word_not_load')}</p>
        )}
      </div>

      {/* Footer section */}
      <div className="footer">
        <button
          onClick={() => window.location = 'mailto:feedback@shaketolearn.com'}
          className="feedback-button"
        >
          Send Feedback
        </button>
        <p>feedback@shaketolearn.com</p>

        <h4>version: MVP 1.0.0</h4>

        <h4>{t('copyright')} {currentYear} </h4>
      </div>

    </>
  );
};

export default PulledTheWord;