import React, { useEffect } from 'react';
import './styles/main.css'; // app.js style
import './styles/desktop.css'; // desktop style
import './utils/i18n'; // languages, i18n.js
import { useTranslation } from 'react-i18next'; // import the useTranslation hook 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

/* import pages */
import WelcomeScreen from './pages/WelcomeScreen';
import Shake from './pages/Shake';
import PulledTheWord from './pages/PulledTheWord';
import Definition from './pages/Definition';
import ExampleSentence from './pages/ExampleSentence';
import Synonyms from './pages/Synonyms';
import Antonyms from './pages/Antonyms';
import YourSentence from './pages/YourSentence';

/* import assets */
import en from './assets/en.jpg';
import shakephone from './assets/shakephone.jpg';
import shakephone2 from './assets/shakephone2.jpg';
import i18n from './utils/i18n';
/* import pleaseshake from './assets/pleaseshake.png'; */

// import ReactGA from "react-ga";
import ReactGA from "react-ga4";

ReactGA.initialize("G-1G9FPC4YT8");

// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

function App() {

  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  {/* languages alert codes here below */ }

  useEffect(() => {
    const checkLanguageSupport = () => {
      const supportedLanguages = ['en', 'tr'];
      let userLanguage = i18n.language || window.navigator.language;

      if (userLanguage.includes('')) {
        userLanguage = userLanguage.split('-')[0];
      }

      const lastWarningTime = localStorage.getItem('lastLanguageWarningTime');
      const now = new Date();

      if (!supportedLanguages.includes(userLanguage) &&
        (!lastWarningTime || now - new Date(lastWarningTime) > 2592000000)) {
        alert(`${userLanguage} language is not currently supported, but it may be added in the future. For now, the default language is set to English.`);
        i18n.changeLanguage('en');
        localStorage.setItem('lastLanguageWarningTime', now.toISOString());
      }
    };

    checkLanguageSupport();

  }, []);

  {/* languages alert codes here above */ }

  return (

    <Router>

      <Routes>


        <Route path="/" element={<div className="main"><WelcomeScreen /></div>} /> {/* first screen on mobile here */}

        <Route path="/Shake" element={<div className="main"><Shake /></div>} /> {/* Shake.js route here */}

        <Route path="/PulledTheWord" element={<div className="main"><PulledTheWord /></div>} /> {/* PulledTheWord.js route here */}

        <Route path="/Definition" element={<div className="main"><Definition /></div>} /> {/* Definition.js route here */}

        <Route path="/ExampleSentence" element={<div className="main"><ExampleSentence /></div>} /> {/* ExampleSentence.js route here */}

        <Route path="/Synonyms" element={<div className="main"><Synonyms /></div>} /> {/* Synonyms.js route here */}

        <Route path="/Antonyms" element={<div className="main"><Antonyms /></div>} /> {/* Antonyms.js route here */}

        <Route path="/YourSentence" element={<div className="main"><YourSentence /></div>} /> {/* YourSentence.js route here */}

      </Routes>

      {/* ALERT SCREEN FOR DESKTOP - NO NEED TO CODE */}
      <div className="desktop-only-alert" >

        <div className="dbrand" >
          <h4>shakeToLearn.com</h4>
          <img src={en} alt="english-logo" ></img>
        </div>

        <div className="dslogan">
          <h1>{t('shake_slogan')}</h1>
        </div>


        <h4>{t('mobile_warning')}</h4>

        <div className="dshakeimgs" >

          <img src={shakephone} alt="" ></img>
          <img src={shakephone2} alt="" ></img>


        </div>

        <h4>{t('copyright')} {currentYear} </h4>

      </div>
      {/* ALERT SCREEN FOR DESKTOP - NO NEED TO CODE ABOVE */}

    </Router>

  );
}

export default App;