import React from 'react';
import '../styles/main.css'; // app.js style
import '../utils/i18n'; // languages, i18n.js
import { useTranslation } from 'react-i18next'; // import the useTranslation hook
import { useNavigate } from 'react-router-dom';


/* import assets */
import en from '../assets/en.jpg';
import shakephone from '../assets/shakephone.jpg';
import shakephone2 from '../assets/shakephone2.jpg';

const WelcomeScreen = () => {

    const { t } = useTranslation();

    const currentYear = new Date().getFullYear();

    {/* For Button Shake Route and permission codes here below */ }

    const navigate = useNavigate();

    const requestPermission = () => {

        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {

            if (typeof DeviceMotionEvent.requestPermission === 'function') {

                DeviceMotionEvent.requestPermission()
                    .then(permissionState => {

                        if (permissionState === 'granted') {
                            navigate('/Shake');
                        } else {
                            alert('Permission not granted!');
                        }
                    })
                    .catch(console.error);
            } else {
                navigate('/Shake');
            }

        } else {
            navigate('/Shake');
        }
    };

    {/* For Button Shake Route and permission codes here above */ }



    return (

        <div className="welcome-screen" >

            {/* <div className="underc" >
                <h6>{t('underc')}</h6>
            </div> */}

            <div className="brand" >
                <h4>shakeToLearn.com</h4>
                <img src={en} alt="english-logo" ></img>
            </div>

            <div className="slogan">
                <h1>{t('shake_slogan')}</h1>
            </div>

            <div className="shakeimgs" >
                <img src={shakephone} alt="" ></img>
                <img src={shakephone2} alt="" ></img>
            </div>

            <div className="startbutton" >
                <button onClick={requestPermission} >{t('start_button_text')}</button>
            </div>

            <div className="footerw">
                <button
                    onClick={() => window.location = 'mailto:feedback@shaketolearn.com'}
                    className="feedback-button"
                >
                    Send Feedback
                </button>
                <p>feedback@shaketolearn.com</p>

                <h4>version: MVP 1.0.0</h4>
                <div className="footerw-info">
                    <a href="https://instagram.com/shaketolearn" target="_blank" rel="noopener noreferrer">
                        instagram: @shaketolearn
                    </a>
                    <p>{t('total_words_in_database')} </p>
                </div>
                <p>{t('copyright')} {currentYear}</p>
            </div>



        </div>

    );
};

export default WelcomeScreen;