import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import pleaseshake from '../assets/pleaseshake.png';

/* import assets */
import en from '../assets/en.jpg';

const Shake = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [word, setWord] = useState(null);
    const [isShakeDetected, setIsShakeDetected] = useState(false);
    const currentYear = new Date().getFullYear();

    // works when shake phone
    const handleShake = useCallback((event) => {

        const acceleration = event.acceleration;
        const threshold = 15; // shakeing sett

        if (acceleration.x > threshold || acceleration.y > threshold || acceleration.z > threshold) {
            setIsShakeDetected(true);
        }
    }, []);


    // fetch random word from API
    const fetchRandomWord = async () => {
        try {
            const response = await fetch('https://shaketolearn.com/api/words/random');
            const data = await response.json();
            setWord(data[0]); // take the first array
        } catch (error) {
            console.error('Error fetching random word:', error);
        }
    };

    useEffect(() => {

        window.addEventListener('devicemotion', handleShake);

        return () => {
            window.removeEventListener('devicemotion', handleShake);
        };
    }, [handleShake]);


    // fetch the word when shakes
    useEffect(() => {
        if (isShakeDetected) {
            fetchRandomWord();
        }
    }, [isShakeDetected]);

    // redirect to pulled word page
    useEffect(() => {
        if (word) {
            navigate('/PulledTheWord', { state: { word } });
        }
    }, [word, navigate]);

    // last screen blow

    return (
        <div className="shake-screen">

            <div className="brand" >
                <h4>shakeToLearn.com</h4>
                <img src={en} alt="english-logo" ></img>
            </div>

            <div className="progress-container">
                <div className="progress-step active">{t('shake')}</div>
                <div className="progress-arrow">&#8594;</div>
                <div className="progress-step">{t('word')}</div>
                <div className="progress-arrow">&#8594;</div>
                <div className="progress-step">{t('definition')}</div>
                <div className="progress-arrow">&#8594;</div>
                <div className="progress-step">{t('examplesentence')}</div>
                <div className="progress-arrow">&#8594;</div>
                <div className="progress-step">{t('synonyms')}</div>
                <div className="progress-arrow">&#8594;</div>
                <div className="progress-step">{t('antonyms')}</div>
                <div className="progress-arrow">&#8594;</div>
                <div className="progress-step">{t('write_yourself')}</div>
            </div>

            <div className="shake-now" >
                <h3>{t('shake_now')}</h3>
            </div>

            <div className="pleaseshake" >
                <img src={pleaseshake} alt="" />
            </div>

            <div className="footer" >

                <button
                    onClick={() => window.location = 'mailto:feedback@shaketolearn.com'}
                    className="feedback-button"
                >
                    Send Feedback
                </button>
                <p>feedback@shaketolearn.com</p>

                <h4>version: MVP 1.0.0</h4>

                <h4>{t('copyright')} {currentYear} </h4>
            </div>

        </div>
    );

};

export default Shake;