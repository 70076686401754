import i18n from "i18next";
import { initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'; // Import language detector


const resources = {
    en: {
        translation: require('../locales/en/translation.json')
    },
    tr: {
        translation: require('../locales/tr/translation.json')
    }
};

i18n
    .use(LanguageDetector) // using lang dedector
    .use(initReactI18next) // for react-i18next transition
    .init({
        resources,
        fallbackLng: "en", // Default Lang, "LNG" not "ING" -> 'fallbackLng'
        keySeparator: false, // Disables the use of periods in translation keys
        interpolation: {
            escapeValue: false // XSS attcak security code
        },

        detection: { // dedect lang options
            order:['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie']
        },
    });

export default i18n;