import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* Import language-specific assets */
import en from '../assets/en.jpg';

const YourSentence = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const word = location.state?.word;
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  /* State for user's sentence and validation */
  const [sentence, setSentence] = useState('');
  const [isValid, setIsValid] = useState(true);

  /* Supported language list */
  const supportedLanguages = ['en', 'tr'];
  let userLanguage = i18n.language.split('-')[0];
  const isSupported = supportedLanguages.includes(userLanguage);

  /* Update sentence and validity on input change */
  const handleSentenceChange = (event) => {
    setSentence(event.target.value);
    setIsValid(!event.target.value || event.target.value.includes(word.word));
  };

  /* Validate and navigate on next click */
  const handleNextClick = () => {
    if (sentence.includes(word.word)) {
      navigate('/Shake');
    } else {
      setIsValid(false);
    }
  };

  /* Navigate to previous page */
  const handleBackClick = () => {
    navigate('/Antonyms', { state: { word } });
  };

  return (
    <>
      {/* Brand logo and name */}
      <div className="brand">
        <h4>shakeToLearn.com</h4>
        <img src={en} alt="english-logo"></img>
      </div>

      {/* Progress bar for navigation steps */}
      <div className="progress-container">
        <div className="progress-step">{t('shake')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('word')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('definition')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('examplesentence')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('synonyms')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step">{t('antonyms')}</div>
        <div className="progress-arrow">&#8594;</div>
        <div className="progress-step active">{t('write_yourself')}</div>
      </div>

      {/* User sentence input section */}
      <div className="yoursentence-container">
        {word ? (
          <div className="general-centered-container">
            {/* Word information display */}
            <div className="top-row-container">
              <div className="left-word-info">
                <img src={word.imagePath} alt={word.word} className="general-small-word-image" />
                <span className="word-title">{word.word}</span>
              </div>
              <div className="right-word-info">
                <span className="general-part-of-speech">{word.partOfSpeech}</span>
                <span className="general-difficulty-level">{word.difficultyLevel}</span>
              </div>
            </div>

            {/* Sentence input field */}
            <div className="yoursentence-section">
              <h1>{t('write_yourself')}</h1>
              <input
                type="text"
                value={sentence}
                onChange={handleSentenceChange}
                className={`sentence-input ${!isValid ? 'invalid-input' : ''}`}
                placeholder={t('enter_sentence')}
              />
              {!isValid && <p className="error-message">{t('word_required', { word: word.word })}</p>}
            </div>

            {/* Navigation buttons */}
            <div className="general-buttons-container">
              <button className="next-button" onClick={handleNextClick}>{t('complete_button')}</button>
              <button className="back-button" onClick={handleBackClick}>{t('back_button')}</button>
            </div>
          </div>
        ) : <p>{t('word_not_load')}</p>}
      </div>

      {/* Footer section */}
      <div className="footer">
        <button
          onClick={() => window.location = 'mailto:feedback@shaketolearn.com'}
          className="feedback-button"
        >
          Send Feedback
        </button>
        <p>feedback@shaketolearn.com</p>

        <h4>version: MVP 1.0.0</h4>

        <h4>{t('copyright')} {currentYear} </h4>
      </div>

    </>
  );
};

export default YourSentence;